import { Component } from 'react';
import ReactDOM from 'react-dom';

class SeoMeta extends Component {
  constructor(props) {
    super(props);
    this.titleEl = document.getElementsByTagName("title")[0];
  }

  render() {
    const match = window.location.href.match(/\/([A-Za-z]*)$/);
    let title = "";
    if (match && match[1]) {
      title =  " - " + match[1].charAt(0).toUpperCase() + match[1].slice(1);
    }
    return ReactDOM.createPortal(
      title,
      this.titleEl
    );
  }
}

export default SeoMeta;
