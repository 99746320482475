import React from "react";
import axios from "axios"
import { withAuth0 } from '@auth0/auth0-react';
import Config from '../Config';

class UploadRoute extends React.Component {
  constructor(props) {
    super(props);
    this.uploadRoute = this.uploadRoute.bind(this);
  }

  async uploadRoute(ev) {
    ev && ev.preventDefault()

    let userName;
    if (this.props.user && this.props.user.name) userName = this.props.user.name;
    const routeFile = ev.target.routeFile.files[0];

    if (!(userName && routeFile)) {
      return
    }
    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('routeNickname', ev.target.routeNickname.value);
    formData.append('file', routeFile);
    ev && ev.target && ev.target.reset();

    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getRoute: ', e);
      return window.location.reload();
    }

    axios
      .post(`${Config.ApiBaseUrl}/uploadRoute`, formData, {headers: {Authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        if (response.data && response.data.err) {
          console.log(response.data.err);
          if (this.props.failure) this.props.failure({
            title: "Failed to upload route:",
            body: "Could not connect to database.  Please try again."
          })
        } else if (response.data && response.data.data) {
          if (this.props.success) this.props.success({
            title: "Route Uploaded!",
            body: routeFile.name
          })
        }
      });

    return false;
  }

  render() {
    return (
      <form className="prof-form" action="#" onSubmit={this.uploadRoute}>
        <input className="prof-form-name" type="text" name="routeNickname" placeholder="File Nickname (optional)"/>
        <input className="prof-form-file" type="file" name="routeFile"/>
        <input className="prof-form-button" type="submit"></input>
      </form>
    );
  }
}

export default withAuth0(UploadRoute);
