import React from "react";
import ProfTile from "./ProfTile";
import ComingSoon from "./ComingSoon";

export default class ProfTalents extends React.Component {
  render() {
    return (
      <ComingSoon>
        <div>Talent 1</div>
        <div>Talent 2</div>
        <div>Talent 3</div>
        <div>Talent 4</div>
      </ComingSoon>
    )
  }
}
