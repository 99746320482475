import React from "react";
import axios from "axios"
import { withAuth0 } from '@auth0/auth0-react';
import { Button, Modal } from "react-bootstrap";
import Config from '../Config';

class ProfPwReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShowModal: false,
      modalTitle: "",
      modalBody: ""
    }
    this.passwordReset = this.passwordReset.bind(this);
  }

  async passwordReset() {
    const user = this.props.auth0.user;
    if (!(user && user.name)) return;
    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getRoute: ', e);
      return window.location.reload();
    }
    const response = await axios.post(`${Config.ApiBaseUrl}/passwordReset`, null, {headers: {Authorization: `Bearer ${accessToken}`}})
    if (response.data && response.data.data) {
      // Success
      this.setState({shouldShowModal: true, modalTitle: "Succcess!", modalBody: "Check your email"})

    } else {
      // Fail
      this.setState({shouldShowModal: true, modalTitle: "Failed to reset password", modalBody: "Check your log in and try again"})
    }
  }

  async closeModal() {
    this.setState({shouldShowModal: false})
  }

  render() {
    return (
      <>
        <Button variant="danger" onClick={this.passwordReset}>Reset Password</Button>
        <Modal show={this.state.shouldShowModal} onHide={this.closeModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.modalBody}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal.bind(this)}>Done</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default withAuth0(ProfPwReset);
