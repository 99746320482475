import './map.css';
import React from "react";
import axios from "axios";
import {Welcome, HowTo, ParaTitle, Inspo, Strava} from "./Welcome"
import SeoMeta from './SeoMeta';
import { Container } from 'react-bootstrap';

export default class About extends React.Component {
  render() {
    const date = new Date();
    return (
      <Container fluid>
        <ParaTitle text="About"></ParaTitle>
        <Welcome></Welcome>
        <Inspo></Inspo>
        <HowTo></HowTo>

        <Strava></Strava>

        <SeoMeta></SeoMeta>
        </Container>
    );
  }
}
