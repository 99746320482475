import './map.css';
import React from "react";
import axios from "axios";
import RaceTile from "./RaceTile";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RaceLine from './RaceLine';
import { Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import SeoMeta from './SeoMeta';
import Config from '../Config';

class Map extends React.Component {
  constructor(props) {
    super(props);

    window.compareRouteWithPylons = this.compareRouteWithPylons.bind(this); // For debugging.
    // window.changePoint = this.changePoint.bind(this);

    this.state = {
      jump: 1,
      isPlayingPublic: false,
      isPlayingUser: false,
      focusedPublicIdx: 0,
      focusedUserIdx: 0,
      publicMarker: null,
      userMarker: null,
      modalShouldShow: false,
      focusedPublicRaces: [],
      focusedUserRace: null,
      publicRaces: [],
      publicPylons: [],
      scoredPylons: [],
      publicPylonMarkers: [],
      userRoutes: [],
      markers: [],
      selectedPylon: {},
      score: 0
    };
  }

  generateMap() {
    // Map can only be generated once.
    if (this.MAP) return;

    const L = window.L;
    this.MAP = L.map('map');
    this.MAP.on('click', (ev) => {
      this.handleClick(ev)
    });
    window.myMap = this.MAP; // For debugging

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.MAP);
    // Have to set the view at some point before we can pan/fly around.
    this.MAP.setView([33.734743, -84.369950], 13); // Grant Park Atlanta
  }

  handleClick(ev) {
    console.log('Map clicked: ', ev, this);
  }

  changePoint(isPublic, isForward) {
    console.log('isPublic, isForward: ', isPublic, isForward);
    let idx;
    const race = isPublic ? this.state.focusedPublicRace : this.state.focusedUserRace;
    if (race && race.getLatLngs) {
      console.log("Got a race: ", race);
      const points = race.getLatLngs();
      const focusedIdx = isPublic ? this.state.focusedPublicIdx : this.state.focusedUserIdx;
      const jump = this.state.jump || 1;
      idx = isForward ? focusedIdx + jump : focusedIdx - jump;
      if (idx >= points.length) idx = 0;
      if (idx < 0) idx = points.length + idx;
      console.log('jump, idx: ', jump, idx);
      const point = points[idx];
      if (point) {
        console.log("Got a point: ", point);
        const opacity = isPublic ? 0.5 : 1;
        const oldMarker = isPublic ? this.state.publicMarker : this.state.userMarker;
        if (oldMarker) this.MAP.removeLayer(oldMarker);
        const marker = window.L.marker(point, {opacity}).addTo(this.MAP);
        this.MAP.panTo(point);

        if (isPublic) {
          this.setState({focusedPublicIdx: idx, publicMarker: marker});
        } else {
          this.setState({focusedUserIdx: idx, userMarker: marker});
        }
      }
    }
  }

  async updateReference(routeReference) {
    const L = window.L;
    const isPublic = routeReference.hasOwnProperty('points');
    const color = isPublic ? 'red' : 'blue';
    if (routeReference.polyline) {
      if (routeReference.selected) {
        routeReference.polyline.setStyle({weight: 2})
      } else if (routeReference.focused) {
        routeReference.polyline.setStyle({weight: 1})
      } else {
        this.MAP.removeLayer(routeReference.polyline);
        delete routeReference.polyline;
      }
    } else {
      if (routeReference.selected) {
        if(!routeReference.raceData) routeReference.raceData = await this.getRoute(routeReference.location);
        const markers = routeReference.raceData.Body.tracks[0].segments[0].map((point) => {
          return [point.lat, point.lon];
        });
        routeReference.polyline = L.polyline(markers, { color, weight: 2, dashArray: '10, 10' });
      } else if (routeReference.focused) {
        if(!routeReference.raceData) routeReference.raceData = await this.getRoute(routeReference.location);
        const markers = routeReference.raceData.Body.tracks[0].segments[0].map((point) => {
          return [point.lat, point.lon];
        });
        routeReference.polyline = L.polyline(markers, { color, weight: 1, dashArray: '10, 10' });
      } else {
        this.MAP.removeLayer(routeReference.polyline);
        delete routeReference.polyline;
      }
    }
    if (routeReference.polyline) {
      routeReference.polyline.addTo(this.MAP);
      this.MAP.fitBounds(routeReference.polyline.getBounds());
    }
    this.compareRouteWithPylons()
  }

  focusReference = async (raceReference) => {
    raceReference.focused = !raceReference.focused;
    this.updateReference(raceReference);
  }

  async getRoute(location) {
    const user = this.props.user;
    if (!(user && user.name)) return;
    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getRoute: ', e);
      return window.location.reload();
    }

    const response = await axios.post(`${Config.ApiBaseUrl}/getRoute`, {location}, {headers: {Authorization: `Bearer ${accessToken}`}})
    if (response.data && response.data.data) {
      return response.data.data;
    } else {
      console.log(`Failed to get route ${location} due to err: `, response.data.err);
      return null;
    }
  }

  async getUserRoutes() {
    const user = this.props.user;
    if (!(user && user.name)) return;
    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getUserRoutes: ', e);
      return window.location.reload();
    }
    axios
      .post(`${Config.ApiBaseUrl}/getUserRoutes`, null, {headers: {Authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        if (response.data && response.data.err) {
          console.log("Failed to get user routes: ", response.data.err);
        } else if (response.data && response.data.data) {
          this.setState({userRoutes: response.data.data});
        }
      })
  }

  clickPylon(pylon) {
    this.setState({selectedPylon: pylon, pylonModalShouldShow: true})
  }

  async getPublicPylons() {
    const { user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = this.props.auth0;
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently({
        audience: "https://rwg-backend/",
        scope: "read:pylons"
      });
    } catch (e) {
      console.log('Failed to get access token for getPublicPylons: ', e);
      return window.location.reload();
    }
    axios
      .get(`${Config.ApiBaseUrl}/getPylons`, {headers: {Authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        if (response.data && response.data.err) {
          console.log("Failed to get public pylons: ", response.data.err);
        } else if (response.data && response.data.data) {
          const publicPylonMarkers = response.data.data.map((pylon) => {
            pylon = pylon.data;
            if (!pylon) return null;
            return window.L.marker(pylon).addTo(this.MAP).on('click', this.clickPylon.bind(this, pylon));
          })
          this.setState({publicPylons: response.data.data, publicPylonMarkers});
        }
      })
  }

  selectForSubmit = (idx, isPublic, race, ev) => {
    const races = isPublic ? this.state.publicRaces : this.state.userRoutes;
    if (!races) return;

    if (isPublic) {
      races[idx].selected = !races[idx].selected
    } else {
      races.forEach((race, raceIdx) => {
        if (raceIdx === idx) {
          race.selected = !race.selected
        } else if (race.selected) {
          race.selected = false
          this.updateReference(race);
        }
      })
    }

    isPublic ? this.setState({publicRaces: races, updatingScore: true}) : this.setState({userRoutes: races, updatingScore: true})
    this.updateReference(races[idx]);
  }

  async compareRouteWithPylons() {
    const userRoute = this.state.userRoutes
      .find((race) => race.selected)

    if (!(userRoute)) {
      this.setState({updatingScore: false, score: 0, scoredPylons: []});
      return;
    }
    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getRoute: ', e);
      return window.location.reload();
    }
    axios
      .post(`${Config.ApiBaseUrl}/compareRouteWithPylons`,
        {location: userRoute.location, publicPylons: this.state.publicPylons},
        {headers: {Authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        if (response.data && response.data.err) {
          console.log("err from compareRouteWithPylons: ", response.data.err);
        } else if (response.data && response.data.data) {
          const {scoredPylons} = response.data.data;
          const score = scoredPylons.reduce((acc, pylon) => (acc + pylon.data.experience), 0);
          this.setState({score, scoredPylons});
        }
        this.setState({updatingScore: false});
      })
  }

  submitRouteWithPylons = async () => {
    const userRoute = this.state.userRoutes
      .find((race) => race.selected)

    if (!(userRoute)) {
      return;
    }

    let accessToken;
    try {
      accessToken = await this.props.auth0.getAccessTokenSilently({audience: "https://rwg-backend/"});
    } catch (e) {
      console.log('Failed to get access token for getRoute: ', e);
      return window.location.reload();
    }

    axios
      .post(`${Config.ApiBaseUrl}/submitRouteWithPylons`,
        {location: userRoute.location, pylonIds: 'todo'},
        {headers: {Authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        if (response.data && response.data.data) {
          const {badges, score} = response.data.data;
          this.setState({modalShouldShow: true, modalScore: score, score: 0, scoredPylons: [], badges});
          this.getUserRoutes();
        } else {
          console.log("err from submitRouteWithPylons: ", response);
        }
      })
      .catch((err) => {
        console.log('submitRouteWithPylons err: ', err);
      })
  }

  componentDidMount() {
    this.generateMap();
    this.getPublicPylons()
    this.getUserRoutes()
  }

  modalHandleClose() {
    this.setState({modalShouldShow: false})
  }

  pylonModalHandleClose() {
    this.setState({pylonModalShouldShow: false})
  }

  render() {
    let userRoutes;
    if (this.state.userRoutes.length > 0) userRoutes = this.state.userRoutes.map((race, idx) => {
      const name = race.name || race.location;
      return <RaceTile key={name} name={name} idx={idx} selected={race.selected}
          raceReference={race}
          focusReference={this.focusReference}
          selectForSubmit={this.selectForSubmit.bind(this, idx, false, race)}
        ></RaceTile>
    })
    const userRaceLines = this.state.userRoutes
      .filter((race) => race.selected)
      .map((race, idx) => {
        const name = race.name || race.location;
        return <RaceLine key={name} name={name}></RaceLine>
      })

    const publicPylonLines = this.state.scoredPylons
      .map((pylon) => {
        return <RaceLine key={pylon.data.name} name={pylon.data.name}></RaceLine>
      })

    const badges = this.state.badges ? this.state.badges.map((b)=>(<><img src={b.location}></img><div>{b.text}</div></>)) : null;

    return (
      <div>
        <Container fluid>
          <Row>
            {/* Map */}
            <Col>
              <div id="map" className="rounded">map</div>
            </Col>

            {/* Races */}
            <Col md={6} lg={4} className="races-tabs-container rounded">

              {/* Tabs */}
              <Tabs defaultActiveKey="userRoutes">
                <Tab className="race-tab" eventKey="userRoutes" title="Routes">
                  {userRoutes}
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Row className="action-row rounded">
            <Col sm={5}>
              <div>
                {userRaceLines}
                {publicPylonLines}
              </div>
            </Col>
            <Col>
                <Row className="score-box">
                  {`Points:`}
                </Row>
                <Row className="score-box">
                  {this.state.updatingScore ? `...` : `${this.state.score}`}
                </Row>
            </Col>
            <Col>
              <Button className="race-submit-button" variant="success" onClick={this.submitRouteWithPylons}></Button>
            </Col>
          </Row>

          {/* Submit route modal (experience and badges). */}
          <Modal show={this.state.modalShouldShow} onHide={this.modalHandleClose.bind(this)}>
            <Modal.Header closeButton>
              <Modal.Title>Route blazed!</Modal.Title>
            </Modal.Header>
            {badges}
            <Modal.Body>
              <p>Awarded {this.state.modalScore} points!</p>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/profile">
                <Button variant="secondary">
                  Profile
                </Button>
              </Link>
              <Link to="/store">
                <Button variant="secondary">
                  Store
                </Button>
              </Link>
              <Button variant="secondary" onClick={this.modalHandleClose.bind(this)}>
                Done
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Pylon information modal. */}
          <Modal show={this.state.pylonModalShouldShow} onHide={this.pylonModalHandleClose.bind(this)}>
            <Modal.Header closeButton>
              <Modal.Title>Pylon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.selectedPylon.name}</p>
              <p>Earn {this.state.selectedPylon.experience} experience.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.pylonModalHandleClose.bind(this)}>
                Done
              </Button>
              <Button variant="secondary" onClick={this.pylonModalHandleClose.bind(this)}>
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
        <SeoMeta></SeoMeta>
      </div>
    );
  }
}

export default withAuth0(Map);
