import React from "react";
import Button from 'react-bootstrap/Button';
import { Globe, InfoSquare, ArrowLeftSquare, ArrowRightSquare, CaretRightSquare, SquareFill, CaretLeftSquare, CircleFill, Circle } from 'react-bootstrap-icons';


export default class RaceTile extends React.Component {
  constructor(props) {
    super(props);

    this.propsId = `raceTileProps${this.props.name}`;
    this.titleId = `raceTileTitle${this.props.name}`;
    const match = this.props.name.match(new RegExp('/attempts/(.*).gpx$'));
    this.shortName = match ? match[1] : this.props.name;


    this.state = {
      selected: this.props.selected
    };
  }

  expandTile = () => {
    const el = document.getElementById(this.propsId);
    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  }

  focusRaceHandler = () => {
    if (!(this.props.raceReference && this.props.focusReference)) return;

    this.props.focusReference(this.props.raceReference);
  }

  selectForSubmit = () => {
    this.props.selectForSubmit()
  }

  render() {
    const containerClass = this.props.selected ? "race-tile-container race-tile-container-selected" : "race-tile-container";
    const selectButtonIcon = this.props.selected ? <CircleFill></CircleFill> : <Circle></Circle>;
    return (
      <>
        <div className={containerClass}>

          <div className="race-tile-details">
            {/* Title */}
            <div id={this.titleId} className="race-tile-title">
              {this.shortName}
            </div>

            {/* Buttons */}
            <div className="race-tile-buttons">
              <Button className="race-tile-button" variant="light" onClick={this.selectForSubmit}>{selectButtonIcon}</Button>
              <Button className="race-tile-button" variant="light" onClick={this.focusRaceHandler}><Globe></Globe></Button>
              {this.props.shouldShowInfo &&
                <Button className="race-tile-button" variant="light" onClick={this.expandTile}><InfoSquare></InfoSquare></Button>
              }
              {this.props.prevPointContinuous &&
                <Button variant="light" onClick={this.props.prevPointContinuous}><CaretLeftSquare></CaretLeftSquare></Button>
              }
              {this.props.prevPoint &&
                <Button variant="light" onClick={this.props.prevPoint}><ArrowLeftSquare></ArrowLeftSquare></Button>
              }
              {this.props.nextPoint &&
                <Button variant="light" onClick={this.props.nextPoint}><ArrowRightSquare></ArrowRightSquare></Button>
              }
              {this.props.nextPointContinuous &&
                <Button variant="light" onClick={this.props.nextPointContinuous}><CaretRightSquare></CaretRightSquare></Button>
              }
              {this.props.stopPointContinuous &&
                <Button variant="light" onClick={this.props.stopPointContinuous}><SquareFill></SquareFill></Button>
              }
            </div>

            {/* Extra info (hidden by default) */}
            <div id={this.propsId} className="race-tile-props hidden">
              <div className="race-tile-prop">
                {this.props.raceReference?.points}
              </div>
              <div className="race-tile-prop">
                {this.props.raceReference?.time}
              </div>
              <div className="race-tile-prop">
                {this.props.raceReference?.distance}
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}
