import React from "react";
import axios from "axios"
import ProfUser from "./ProfUser";
import ProfLeaderboard from "./ProfLeaderboard";
import ProfTalents from "./ProfTalents";
import UploadRoute from "./UploadRoute";
import SeoMeta from "./SeoMeta";
import { Modal, Button, Container, ProgressBar } from "react-bootstrap";
import { withAuth0 } from '@auth0/auth0-react';
import ProfPwReset from "./ProfPwReset";
import { ParaTitle } from "./Welcome";
import Badge from "./Badge";

const EXPERIENCE_PER_LEVEL = 1000;

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  success({title, body}) {
    this.setState({modalShouldShow: true, modalTitle: title, modalBody: body})
  }

  failure({title, body}) {
    this.setState({modalShouldShow: true, modalTitle: title, modalBody: body})
  }

  modalHandleClose() {
    this.setState({modalShouldShow: false})
  }

  render() {
    const exp = this.props.user.experience % EXPERIENCE_PER_LEVEL;
    const lvl = Math.floor(this.props.user.experience / EXPERIENCE_PER_LEVEL);
    const badges = (this.props.user.badges || []).map((badge) => {
      return <Badge badge={badge}></Badge>
    });
    return (
      <Container fluid>
        Level {lvl}
        <ProgressBar now={exp} label={`${exp}%`} max={EXPERIENCE_PER_LEVEL} srOnly />

        <ParaTitle text="User Information"></ParaTitle>
        <ProfUser user={this.props.user}></ProfUser>

        <ParaTitle text="Upload Route"></ParaTitle>
        <UploadRoute user={this.props.user} success={this.success.bind(this)} failure={this.failure.bind(this)}></UploadRoute>

        { badges.length > 0 &&
          <>
            <ParaTitle text="Badges"></ParaTitle>
            {badges}
          </>
        }


        <ParaTitle text="Leaderboard"></ParaTitle>
        <ProfLeaderboard></ProfLeaderboard>

        <ParaTitle text="Talents"></ParaTitle>
        <ProfTalents></ProfTalents>

        <ParaTitle text="Danger Zone"></ParaTitle>
        <ProfPwReset></ProfPwReset>

        <SeoMeta></SeoMeta>
        <Modal show={this.state.modalShouldShow} onHide={this.modalHandleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.modalBody}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.modalHandleClose.bind(this)}>Done</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default withAuth0(Profile);
