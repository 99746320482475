import axios from "axios";
import React from "react";
import ProfTile from "./ProfTile";
import Config from '../Config';

export default class ProfLeaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { leaderboard: [] };
  }

  getLeaderboard() {
    axios
      .post(`${Config.ApiBaseUrl}/getLeaderboard`)
      .then((response) => {
        if (response.data.err) {
          console.log(response.data.err);
        } else if (response.data.data) {
          this.setState({ leaderboard: response.data.data })
        }
      });
  }

  componentDidMount() {
    this.getLeaderboard();
  }

  render() {
    const leaderboardMembers = this.state.leaderboard.map((member) => <ProfTile key={member.name} title={member.name} value={member.score}></ProfTile>);
    return (
      <>
        <div className={`prof-leaderboard`}>
          {leaderboardMembers}
        </div>
      </>
    )
  }
}
