import React from "react";

export class Welcome extends React.Component {
  render() {
    return (
      <div>
        Calling All TRAVELERS
      </div>
    );
  }
}

export class Inspo extends React.Component {
  render() {
    return (
      <div>
        The digital millenium pervades,
        an electronic adventure awaits.
        Go with a purpose.
        Blaze your trail on the Map!
      </div>
    );
  }
}

export class HowTo extends React.Component {
  render() {
    return (
      <div>
        The primary goal of a Blazer is to earn experience to level up their Profile.  Experience is gained by passing near pylons.  View the Map to see what pylons are active near you.  To start, record a GPX file of your route, and upload it to your Profile.  Then, Blaze the Route on the Map to earn experience.  Plan your routes to utilize your Talents and pass by the  best pylons to maximize your experience and level up more quickly!
        At maximum level, you will receive the completion Badge for the current Season, and you can chose to Prestige your Profile or participate in Leaderboards.  The adventure continues onward!
      </div>
    );
  }
}

export class Rules extends React.Component {
  render() {
    return (
      <div>
        You may not upload the same file or a modified version of the same file representing a Route more than once.  (Uploading multiple Routes that follow the same directions is fine as long as they represent different activities.)
      </div>
    );
  }
}

export class Strava extends React.Component {
  render() {
    return (
      <div>
        1. Record a ride
        2. Go to your ride on your computer (the website), and download the GPX file
        3. Upload the file to your Profile
      </div>
    );
  }
}

export class Alpha extends React.Component {
  render() {
    return (
      <div>
        Hello!  Thank you for using the site.  We are currently in "Alpha" release mode.  During this release and until we move to the next release phase, some of the app features will not be available, and bugs and broken behavior are expected to occur.  Please keep this in mind when using the site.

        Specific to Alpha release mode:
          • Ongoing development will continue while Alpha users use the site
          • The UI is under active development and will be changing
          • Experience and leveling calculations are still being refined and may change
          • Prestige, Talents, Leaderboards and the Store are disabled
          • After Alpha mode, Experience earned may be reset and badges awarded may be removed except for the Alpha Release Historical Badge
          • You may see or find parts of the site that are still under construction
          • We reserve the right to modify individual profiles as necessary to protect the integrity of the site

        Please report any issues you experience to support@blaze.dog.
        Create an account if you would like to receive notifications like when the site enters Beta release mode and our 1.0 release.

        Thanks,
        Blaze Dog Team
      </div>
    );
  }
}

export class Beta extends React.Component {
  render() {
    return (
      <div>
        Hello!  Thank you for using the site.  We are currently in "Beta" release mode.  During this release and until we move to the next release phase, most of the site features will be available, but bugs and broken behavior may still occur.  Please keep this in mind when using the site.

        Specific to Beta release mode:
          • Ongoing development will continue while Beta users use the site
          • Prestige and the Store is disabled
          • Experience, leveling, and Talents calculations are still being refined and may change
          • After Beta mode, Experience earned may be reset and badges awarded may be removed except for the Beta Release Historical Badge
          • We reserve the right to modify individual profiles as necessary to protect the integrity of the site

        Please report any issues you experience to support@blaze.dog.
        Create an account if you would like to receive notifications like when we make our 1.0 release.

        Thanks,
        Blaze Dog Team
      </div>
    );
  }
}

export class Season extends React.Component {
  render() {
    return (
      <div>
        Hello!  Thank you for using the site.  This is now the 1.0 release, which means all of this season's features are available, and we have attempted to eliminate all buggy behavior previously reported.  However, this is the first seasons open to the public, so we are calling this "Season 0" to represent its inagural nature.  Please keep this in mind when using the site.

        Specific to 1.0 release mode:
          • The Store is still diabled, we are working with our partners to bring it to life in a sustainable way
          • Development is paused on available features
          • As always, we reserve the right to modify individual profiles as necessary to protect the integrity of the site

        Please report any issues you experience to support@blaze.dog.
        Create an account if you would like to receive notifications about new Seasons and features.

        Thanks,
        Blaze Dog Team
      </div>
    );
  }
}

export function Logo() {
  return (
    <img src="dog_logo_1.png" className="logo-img-large" />
  );
}

export function ParaTitle(props) {
  return (
    <div className="para-title">{props.text}</div>
  );
}
