import React from "react";
import ProfTile from "./ProfTile";

export default class UserProf extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const userProps = !this.props.user ? null : Object.keys(this.props.user).map((prop) => <div>{prop}: {this.props.user[prop]}</div>)
    return (
      <>
        <div className="prof-user">
          <img src="https://s.gravatar.com/avatar/37f493b3ae346e288112f0dae1102192?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fcb.png" className="prof-img" />
          <ProfTile title="user" value={this.props.user.nickname}></ProfTile>
          <ProfTile title="experience" value={this.props.user.experience}></ProfTile>
        </div>
      </>
    )
  }
}
