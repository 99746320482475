import React from "react";

export default class ProfTile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='prof-tile'>
        <div className={`prof-tile-value`}>{this.props.value}</div>
        <div className={`prof-tile-title`}>{this.props.title}</div>
      </div>
    )
  }
}
