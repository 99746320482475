import React from "react";
import { Card, Button } from "react-bootstrap";

export default class Badge extends React.Component {
  render() {
    return (
      <Card className="badge">
        <Card.Img variant="top" src={this.props.badge.imgSrc} />
        <Card.Body>
          <Card.Title className="badge-title">{this.props.badge.name}</Card.Title>
          {/* <Card.Text>
          Some quick example text to build on the card title and make up the bulk of
          the card's content.
        </Card.Text> */}
        </Card.Body>
      </Card>
    )
  }
}
