import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from "axios"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import Map from "./components/Map"
import Profile from "./components/Profile"
import About from "./components/About"
import Login from "./components/Login"
import { Map as MapIcon, JournalText, Person, Gift } from 'react-bootstrap-icons';
import { useAuth0 } from '@auth0/auth0-react';
import Store from './components/Store';
import { Welcome, HowTo, Logo, ParaTitle } from './components/Welcome';
import SeoMeta from './components/SeoMeta';
import Config from './Config';

// Set this early on to work with cors in dev mode.
axios.defaults.withCredentials = true;

export default function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    loginWithPopup,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const [dbUser, setDbUser] = useState(0);
  const combinedUser = Object.assign({}, user, dbUser)
  useEffect(() => {
    const getUser = async () => {
      if (!(user && user.name)) return;
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently({audience: "https://rwg-backend/"});
      } catch (e) {
        console.log('Failed to get access token for getUser: ', e);
        return window.location.reload();
      }
      const response = await axios.post(`${Config.ApiBaseUrl}/getUser`, null, {headers: {Authorization: `Bearer ${accessToken}`}})
      if (response.err) {
        console.log(response.err);
      } else if (response.data) {
        setDbUser(response.data)
      }
    }
    getUser()
  }, [user]);
  const currentPath = useLocation().pathname

  if (isAuthenticated) {
    return (
      <div className="root-div">
          <div className="menu">
            <Link to="/map"><div className={currentPath == "/map" ? "menu-button menu-button-selected" : "menu-button"}><div className="menu-button-text">Map</div><MapIcon></MapIcon></div></Link>
            <Link to="/profile"><div className={currentPath == "/profile" ? "menu-button menu-button-selected" : "menu-button"}><div className="menu-button-text">Profile {combinedUser.experience}</div><Person></Person></div></Link>
            {/* <Link to="/store"><div className={currentPath == "/store" ? "menu-button menu-button-selected" : "menu-button"}><div className="menu-button-text">Store</div><Gift></Gift></div></Link> */}
            <Link to="/about"><div className={currentPath == "/about" ? "menu-button menu-button-selected" : "menu-button"}><div className="menu-button-text">About</div><JournalText></JournalText></div></Link>
            <div className="loginout-wrapper">
              <Login  loginWithPopup={loginWithPopup} isAuthenticated={isAuthenticated} logout={logout} isLoading={isLoading}></Login>
            </div>
          </div>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch >
            <Route exact path="/">
              <Redirect to="/map" />
            </Route>
            <Route path="/about">
              <About user={combinedUser} setDbUser={setDbUser} />
            </Route>
            <Route path="/profile">
              <Profile user={combinedUser} setDbUser={setDbUser} />
            </Route>
            <Route path="/map">
              <Map user={combinedUser} setDbUser={setDbUser} />
            </Route>
            <Route path="/store">
              <Store user={combinedUser} setDbUser={setDbUser} />
            </Route>
            <Route>
              NOT FOUND
            </Route>
          </Switch>
      </div>
    );
  }

  return (
    <div className="root-div">
      <div className="menu">
        <div className="loginout-wrapper">
          <Login loginWithPopup={loginWithPopup} isAuthenticated={isAuthenticated} logout={logout} isLoading={isLoading}></Login>
        </div>
      </div>
      <div className="centered main-logo-container"><Logo></Logo></div>

      <Welcome></Welcome>
      <HowTo></HowTo>

      <SeoMeta></SeoMeta>
    </div>
  );
}
