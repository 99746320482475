import React from "react";
import Item from "./Item";
import ComingSoon from "./ComingSoon";
import SeoMeta from "./SeoMeta";
import { Container } from "react-bootstrap";
import { ParaTitle } from "./Welcome";

export default class Store extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const date = new Date();
    const items = ITEMS.map((item) => <Item key={item.id || Math.random()} item={item}></Item>)
    return (
      <Container fluid>
        <ParaTitle text="Store"></ParaTitle>
        <ComingSoon>
          <div>
            {items}
          </div>
        </ComingSoon>

        <SeoMeta></SeoMeta>
      </Container>
    )
  }
}

const ITEMS = [
  {
    name: "Maxxis Minion DHF",
    detailedName: "Maxxis Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 2 Minion DHF",
    detailedName: "Maxxis TEST 2 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 3 Minion DHF",
    detailedName: "Maxxis TEST 3 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 4 Minion DHF",
    detailedName: "Maxxis TEST 4 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 5 Minion DHF",
    detailedName: "Maxxis TEST 5 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 6 Minion DHF",
    detailedName: "Maxxis TEST 6 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  },
  {
    name: "Maxxis TEST 7 Minion DHF",
    detailedName: "Maxxis TEST 7 Minion DHF Wide Trail Dual Compound/EXO/TR Tire - 29in",
    price: "59.00g",
    imgSrc: "Maxxis_Minion_DHF.jpg"
  }
]
