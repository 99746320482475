import React from "react";
// import Button from 'react-bootstrap/Button';
// import { Globe, InfoSquare, ArrowLeftSquare, ArrowRightSquare, CaretRightSquare, SquareFill, CaretLeftSquare, CircleFill, Circle } from 'react-bootstrap-icons';
import { Spinner, Button } from "react-bootstrap";


export default function Login(props) {
  const loginWithPopupSafe = async () => {
    try {
      props.loginWithPopup()
    } catch (e) {
      console.log('Caught errror e: ', e);
    }
  }
  let button;
  if (props.isAuthenticated && props.logout) {
    button = <Button onClick={() => props.logout({ returnTo: window.location.href })}>Log Out</Button>
  } else if (props.loginWithPopup) {
    button = <Button variant="primary" onClick={() => {loginWithPopupSafe()}}>Log In</Button>
  }
  return (
    <>
      {button}
    </>
  )
}
