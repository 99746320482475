import React from "react";

export default class RaceLine extends React.Component {
  render() {
    const match = this.props.name.match(new RegExp('/attempts/(.*).gpx$'));
    const shortName = match ? match[1] : this.props.name;
    return (
      <>
        <div className={`race-line`}>{shortName}</div>
      </>
    )
  }
}
