import React from "react";

export default class ComingSoon extends React.Component {
  render() {
    return (
      <div className="coming-soon-container">
        <div className="coming-soon">
          <div className="coming-soon-text">
            <p>Coming Soon</p>
            <p>{this.props.message}</p>
          </div>
        </div>
        <div className="coming-soon-children">
          {this.props.children}
        </div>
      </div>
    )
  }
}
