// in all environments except local, the api is located on the same host and port as the UI
var apiBaseUrl = '/api';

if (window.location.hostname === 'localhost') {
    apiBaseUrl = 'http://localhost:5000/api';
}

export default {
    ApiBaseUrl: apiBaseUrl
};
